import React from 'react';

import withBg from '../../helpers/withBg';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';
import GetCallBackForm from '../../components/GetCallBackForm/GetCallBackForm';
import './styles.css';

const data = {
  title: 'Contact US',
  seoTitle: 'Contact US | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content contact-us-content">
              <div className="text-block">
                <h1>Contact Us</h1>
                <p>Let us know how we can help you.</p>
                <h3>Help & Support</h3>
                <p>
                  If you have a question or need assistance with your travel:
                </p>
                <span>1-855-340-6333</span>
                <p>Open 24 hours a day, 7 days a week.</p>
                <p>
                  All phone messages and emails will be replied within 24 hrs.
                </p>
                <h3>Sales</h3>
                <p>
                  If you would like to discuss marketing opportunity, business
                  development & strategic partnership, please fill the form and
                  mention the related subject.
                </p>
                <h3>Affiliate & Partners</h3>
                <p>
                  If you would like to discuss being a Partner or Affiliate
                  please contact us using the above details.
                </p>
                <h3>Address</h3>
                <p>
                  1111 W El Camino Real Ste 109-240, Sunnyvale, CA 94087, USA
                </p>
              </div>
            </div>
            <GetCallBackForm />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(ContactUs);
